import React from "react";
import { Helmet } from "react-helmet";

const Head = () => {
  return (
    <>
      <Helmet>
        <link href="https://fonts.gstatic.com" rel="preconnect" />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="//cdn.jsdelivr.net/gh/kenwheeler/slick@1.9.0/slick/slick.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="//cdn.jsdelivr.net/gh/kenwheeler/slick@1.9.0/slick/slick-theme.css"
        />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />

        <title>【公式】庄内浜釣りケーション</title>
        {/* ogp */}
        <meta
          name="description"
          content="「庄内浜釣りケーション」庄内での釣り・ワークスペース・泊まる・情報特集 - 山形県庄内"
        />
        <meta property="og:title" content="【公式】庄内浜釣りケーション" />
        <meta
          property="og:description"
          content="「庄内浜釣りケーション」庄内での釣り・ワークスペース・泊まる・情報特集 - 山形県庄内"
        />
        <meta property="og:url" content="https://shonai-tsurication.jp/" />
        <meta
          property="og:image"
          content="https://shonai-tsurication.jp/images/ogp.png"
        />
        <meta name="twitter:card" content="" />
        <meta property="og:type" content="website" />
        <meta name="google" content="notranslate" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap"
          rel="stylesheet"
        />
        <script>
          {`
  (function(d) {
    var config = {
      kitId: 'qrb8zof',
      scriptTimeout: 3000,
      async: true
    },
    h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
  })(document);`}
        </script>
        {/* ogp */}
      </Helmet>
    </>
  );
};

export default Head;
